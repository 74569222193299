<template>
  <div class="pufa-page">
    <img src="//download-cos.yofish.com/youyu-gongjushiyebu/20220506152452860-1.png" alt="">
    <div class="pufa-content">
      <div class="head">
        <img src="//download-cos.yofish.com/youyu-gongjushiyebu/20220506152741499-11.png" alt="">
        <div class="button" @click="jumpRule"><span>活动细则</span></div>
      </div>
      <div class="head-title">
        <img src="//download-cos.yofish.com/youyu-gongjushiyebu/2022050615272572-10.png" alt="">
        <img src="//download-cos.yofish.com/youyu-gongjushiyebu/20220506152638227-7.png"
          class="market" alt="">
      </div>
      <div class="head-title-row">
        <img src="//download-cos.yofish.com/youyu-gongjushiyebu/20220506152607624-5.png"
          class="gold" alt="">
        <p class="big">新客特享</p>
        <p class="small">专属福利送不停</p>
      </div>
      <div class="frame-row">
        <div class="red-bg">
          <img src="//download-cos.yofish.com/youyu-gongjushiyebu/20220506152653197-8.png"
           alt="">
        </div>
        <img src="//download-cos.yofish.com/youyu-gongjushiyebu/20220506152537869-3.png"
          class="img-bg" alt="">
        <div class="content-text">
          <p>1.活动时间：<br/>
            2022年5月9日起至2022年6月30日
          </p>
          <p>2.活动对象：活动期间通过有鱼记账APP指定推送页面成功申请并获批的浦发银行信用卡主卡的新客户（以下简称“持卡人”）。
            新客户指满足从未持有浦发银行信用卡或参加本活动（即提交信用卡申请）时已注销所有浦发银行信用卡12月（含）以上的主卡客户。
          </p>
          <p>3. 活动内容：<br/>
          活动期间，通过有鱼记账APP指定页面，成功申请浦发银行信用卡主卡的新客户，并于核卡通过后60天内使用该卡任意合格消费1笔，
          即可获得如下权益：（每位新客户限参与1次）
          <br />
          ①获得 <span class="red">1年有鱼记账APP会员权益</span>
          <br />
          ②获得
          <span class="red">50元现金红包</span>
          </p>
        </div>
      </div>
      <div class="reward-tips">
        奖励发放：
        <br />
        奖励将于客户满足消费条件后的次月底前发放，其中有鱼记账APP会员权益发放至其有鱼记账APP账户；50元现金红包发放至有鱼记账APP-我的-钱包。
      </div>
      <div class="frame-card">
        <img src="//download-cos.yofish.com/ad-gongjushiyebu/2022050710372447-frames2.png"
          class="card-bg" alt="">
      </div>
      <div class="tips-text">
        <p>温馨提示：如有任何疑问请咨询客服：400-655-6021，或可联系有鱼</p>
        <p>记账微信客服：youyujz002，客服工作时间为每日09:00-18:00。</p>
      </div>
      <div class="jump-btn" @click="jumpDetails">
        <img src="//download-cos.yofish.com/ad-gongjushiyebu/20220507103737398-submit-btn.png"
        alt="">
      </div>
    </div>
  </div>
</template>
<script>

export default {
  name: 'Pufa',
  mounted() {
    document.title = '浦发银行活动页';
  },
  methods: {
    jumpRule() {
      this.$router.push('/leaflet/pufa/rule');
    },
    jumpDetails() {
      this.$router.push({
        path: '/leaflet/jump',
        query: {
          paramV: 'fdgsdgs3d1',
        },
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.pufa-page {
  background-color: rgb(155, 46, 42);
  font-size: 0;
  width: 100%;
  max-width: 750px;
  min-height: 100%;
  position: relative;
  padding-bottom: 60px;
  overflow-x: hidden;
  > img {
    max-width: 100%;
    position: absolute;
    left: 0;
    top: 0;
  }
  .pufa-content {
    position: relative;
    z-index: 100;
  }
  // background-image: url('//download-cos.yofish.com/youyu-gongjushiyebu/20220506152452860-1.png');
  .head {
    display: flex;
    padding: 22px 30px 0 25px;
    justify-content: space-between;
    img {
      width: 400px;
    }
    .button {
      width: 136px;
      height: 37px;
      background: linear-gradient(35deg, #FFAF45, #FCD5A2);
      border-radius: 18px;
      line-height: 37px;
      text-align: center;
      span {
        font-style: italic;
        font-size: 23px;
        font-family: Source Han Sans CN;
        font-weight: 500;
        color: #FFFFFF;
        padding-right: 4px;
        background: linear-gradient(35deg, #E52D27 0%, #B31217 100%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
      }
    }
  }
  .head-title {
    text-align: center;
    margin-top: 53px;
    position: relative;
    > img {
      width: 626px;
    }
    .market {
      width: 94px;
      position: absolute;
      left: 0;
      top: 0;
    }
  }
  .head-title-row {
    margin-top: 62px;
    text-align: center;
    position: relative;
    .gold {
      position: absolute;
      width: 60px;
      top: 50px;
      left: 86px;
    }
    p.big {
      font-size: 67px;
      font-family: Fontquan-XinYiGuanHeiTi;
      font-weight: 400;
      font-style: italic;
      color: #FFFFFF;
      line-height: 95px;
      background: linear-gradient(0deg, #FFAF45 0%, #FFF0DC 100%);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }
    p.small {
      margin-top: 20px;
      font-size: 36px;
      font-family: Fontquan-XinYiGuanHeiTi;
      font-weight: 400;
      font-style: italic;
      color: #FFFFFF;
      line-height: 51px;
      background: linear-gradient(0deg, #FFAF45 0%, #FFF0DC 100%);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }
  }
  .frame-row {
    width: 714px;
    position: relative;
    margin: 37px auto 0;
    .red-bg {
      width: 84px;
      position: absolute;
      right: 0;
      top: -50px;
      img {
        width: 100%;
      }
    }
    .img-bg {
      width: 100%;
    }
    .content-text {
      position: absolute;
      z-index: 20;
      top: 0;
      left: 0;
      padding: 53px 45px 0 45px;
      p {
        font-size: 26px;
        font-family: Source Han Sans CN;
        font-weight: 500;
        color: #000000;
        line-height: 40px;
        margin-bottom: 25px;
        .red {
          color: #E32C27;
        }
      }
    }
  }
  .reward-tips {
    font-size: 24px;
    font-family: Source Han Sans CN;
    font-weight: 400;
    color: #FFFFFF;
    line-height: 35px;
    padding: 27px 38px 0 38px;
  }
  .frame-card {
    width: 730px;
    position: relative;
    .card-bg {
      max-width: 100%;
      margin-left: -55px;
      margin-top: -55px;
    }
    .card {
      margin: 177px 30px 0 30px;
      position: absolute;
      left: 0;
      top: 0;
      display: flex;
      li {
        width: 174px;
        flex: 0 0 auto;
        > .img {
          width: 100%;
          img {
            width: 100%;
          }
        }
        > p {
          text-align: center;
          font-size: 24px;
          font-family: Fontquan-XinYiGuanHeiTi;
          font-weight: 400;
          color: #FFFFFF;
          line-height: 40px;
          background: linear-gradient(35deg, #E52D27 0%, #B31217 100%);
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
          &.text2, &.text3 {
            font-size: 20px;
          }
        }
      }
    }
  }
  .tips-text {
    margin: 56px 20px 0 20px;
    font-size: 22px;
    font-family: Source Han Sans CN;
    font-weight: 400;
    color: #FFFFFF;
    line-height: 40px;
  }
  .jump-btn {
    width: 680px;
    margin: 115px auto 0;
    img {
      width: 100%;
    }
  }
}
</style>
